var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Manage Term & Session")])])],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":24,"sm":24,"xs":24}},[_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"is_active",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s((row.is_active === '1') ? 'Active' : 'Inactive')+" ")])}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":"1","inactive-value":"0","disabled":row.is_active == '1'},on:{"change":function($event){return _vm.toggleTermActivation(row.id, $event)}},model:{value:(row.is_active),callback:function ($$v) {_vm.$set(row, "is_active", $$v)},expression:"row.is_active"}})],1)}}]),model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}})],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"md":6,"sm":8,"xs":24}},[_c('strong',[_vm._v("Create New Session")]),_c('el-input',{attrs:{"type":"text","placeholder":"Session Name. E.g 2021/2022"},model:{value:(_vm.session_name),callback:function ($$v) {_vm.session_name=$$v},expression:"session_name"}}),_c('hr'),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.createSession()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" Save ")],1)],1),_c('el-col',{attrs:{"md":18,"sm":16,"xs":24}},[_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"is_active",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s((row.is_active === '1') ? 'Active' : 'Inactive')+" ")])}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":"1","inactive-value":"0"},on:{"change":function($event){return _vm.toggleSessionActivation(row.id, $event)}},model:{value:(row.is_active),callback:function ($$v) {_vm.$set(row, "is_active", $$v)},expression:"row.is_active"}})],1)}}]),model:{value:(_vm.sessions),callback:function ($$v) {_vm.sessions=$$v},expression:"sessions"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }